<template>
  <div class="main">
    <img
      class="img"
      src="../../assets/404.png"
      alt="404"
    >
  </div>
</template>

<style></style>

<script>
// import { Screen } from 'quasar'
export default {
  name: 'MainError'
};
</script>
<style lang="stylus" scoped>
.main {
  min-height: 30vh;
  max-width: 80%;
  margin: auto;
}

.img {
  width: 100%;
}
</style>
