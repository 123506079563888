<template>
  <div class="main">
    <div class="divContainer">
      <div>
        <p class="title">
          <span class="test">4</span>04
        </p>
        <p class="subTitle">
          We're Building Modern And High Software
        </p>
      </div>
      <q-breadcrumbs class="breadcrumbs">
        <q-breadcrumbs-el
          label="Home"
          to="/"
        />
        <q-breadcrumbs-el
          label="Pages"
          to="/"
        />
        <q-breadcrumbs-el label="Error 404" />
      </q-breadcrumbs>
    </div>
  </div>
</template>

<style></style>

<script>
// import { Screen } from 'quasar'
export default {
  name: 'HeaderError',
};
</script>
<style lang="stylus" scoped>
@media (max-width: 600px) {
  .main {
    min-height: 30vh;
    max-width: 80%;
    margin: auto;
  }

  .divContainer {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .title {
    font-size: 28px;
    color: #1c1d3e;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  .subTitle {
    font-size: 14px;
    font-style: normal;
    letter-spacing: 1px;
  }

  .breadcrumbs {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }

  .test {
    color: #2575fc;
  }
}

@media (min-width: 601px) and (max-width: 1022px) {
  .main {
    min-height: 30vh;
    max-width: 80%;
    margin: auto;
  }

  .divContainer {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title {
    font-size: 40px;
    color: #1c1d3e;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  .subTitle {
    font-size: 17px;
    font-style: normal;
    letter-spacing: 1px;
  }

  .breadcrumbs {
    font-size: 15px;
    font-weight: bold;
  }

  .test {
    color: #2575fc;
  }
}

@media (min-width: 1023px) {
  .main {
    min-height: 30vh;
    max-width: 80%;
    margin: auto;
  }

  .divContainer {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 50px;
    color: #1c1d3e;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  .subTitle {
    font-size: 17px;
    font-style: normal;
    letter-spacing: 1px;
  }

  .breadcrumbs {
    font-size: 16px;
    font-weight: bold;
  }

  .test {
    color: #2575fc;
  }
}
</style>
