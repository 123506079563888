<template>
  <div class="main">
    <q-card
      v-if="$route.path != '/contact'"
      class="my-card"
    >
      <q-card-section>
        <div class="divSub">
          <div class="divSubmd">
            <p class="title">
              Subscribe Our Newsletter
            </p>
            <p class="subTitle">
              Get started for 1 Month free trial No Purchace required.
            </p>
          </div>
          <div>
            <q-btn
              push
              class="btnmd"
              color="primary"
              size="14px"
              icon-right="exit_to_app"
              label="Let's Talk"
              no-caps
              @click="$router.push('/contact')"
            />
          </div>
          <q-btn
            push
            class="btnxs"
            color="primary"
            size="14px"
            icon-right="exit_to_app"
            label="Let's Talk"
            no-caps
            @click="$router.push('/contact')"
          />
        </div>
      </q-card-section>
    </q-card>
    <div class="divSubFooter">
      <p class="subTitle">
        Copyright 2020 300Dev. | All Rights Reserved
      </p>
      <div class="social">
        <p>
          <q-btn
            color="white"
            flat
            text-color="black"
            icon="facebook"
            label="Facebook"
            no-caps
          />
        </p>
        <p>
          <q-btn
            color="white"
            flat
            text-color="black"
            :icon="ionLogoTwitter"
            label="Twitter"
            no-caps
          />
        </p>
        <p>
          <q-btn
            color="white"
            flat
            text-color="black"
            :icon="ionLogoYoutube"
            label="Youtube"
            no-caps
          />
        </p>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { ionLogoTwitter } from '@quasar/extras/ionicons-v5';
import { ionLogoYoutube } from '@quasar/extras/ionicons-v5';

export default {
  name: 'Footer',
  created() {
    this.ionLogoTwitter = ionLogoTwitter;
    this.ionLogoYoutube = ionLogoYoutube;
  }
};
</script>
<style lang="stylus" scoped>
@media (max-width: 600px)
  .main
    min-height  : 22vh

  .my-card
    max-width: 90%
    margin : auto
    margin-bottom : 2rem

  .title
    font-size : 18px
    line-height: 24px
    font-style: normal
    color: #1c1d3e
    letter-spacing: 2px

  .subTitle
    color: rgb(95, 95, 95)
    font-size : 17px
    line-height: 1.7
    letter-spacing: 1px

  .divSub
    display : flex
    flex-direction : column
    justify-content : center


  .divSubFooter
    max-width : 80%
    min-height  : 20vh
    margin : auto
    display : flex
    flex-direction : column
    justify-content : flex-end
    align-items : center

  .social
    display : flex

  .btnxs
    font-weight : 700
    padding : 7px 0px
    margin-top : 1rem
    margin : 0.2rem

  .btnmd
    display : none
@media (min-width: 601px) and (max-width : 765px)
  .main
    min-height  : 22vh

  .my-card
    max-width: 90%
    margin : auto
    margin-bottom : 2rem

  .title
    font-size : 20px
    line-height: 24px
    font-style: normal
    color: #1c1d3e
    letter-spacing: 2px

  .subTitle
    color: rgb(95, 95, 95)
    font-size : 17px
    line-height: 1.7
    letter-spacing: 1px

  .divSub
    display : flex
    flex-direction : column
    justify-content : center


  .divSubFooter
    max-width : 80%
    min-height  : 20vh
    margin : auto
    display : flex
    flex-direction : column
    justify-content : flex-end
    align-items : center

  .social
    display : flex

  .btnxs
    font-weight : 700
    padding : 7px 0px
    margin-top : 1rem
    margin : 0.2rem

  .btnmd
    display : none
@media (min-width: 766px) and (max-width : 1022px)
  .main
    min-height  : 22vh

  .my-card
    max-width: 80%
    margin : auto

  .title
    font-size : 18px
    line-height: 24px
    font-style: normal
    color: #1c1d3e
    letter-spacing: 2px

  .subTitle
    color: rgb(95, 95, 95)
    font-size : 15px
    line-height: 1.7
    letter-spacing: 1px

  .divSub
    display : flex
    justify-content : space-between
    align-items : center
    padding : 10px


  .divSubFooter
    margin-top : 2rem
    max-width : 80%
    min-height  : 20vh
    margin : auto
    display : flex
    justify-content : space-between
    align-items : flex-end

  .social
    display : flex

  .btnxs
    display : none

  .btnmd
    font-weight : 550
    padding : 10px 0px

  .divSubmd
    width : 60%

@media (min-width : 1023px)
  .main
    min-height  : 22vh

  .my-card
    max-width: 80%
    margin : auto

  .title
    font-size : 20px
    line-height: 24px
    font-style: normal
    color: #1c1d3e
    letter-spacing: 2px

  .subTitle
    color: rgb(95, 95, 95)
    font-size : 17px
    line-height: 1.7
    letter-spacing: 1px

  .divSub
    display : flex
    justify-content : space-between
    align-items : center
    padding : 0px 40px


  .divSubFooter
    margin-top : 2rem
    max-width : 80%
    min-height  : 20vh
    margin : auto
    display : flex
    justify-content : space-between
    align-items : flex-end

  .social
    display : flex

  .btnxs
    display : none

  .btnmd
    font-weight : 550
    padding : 7px 0px
</style>
