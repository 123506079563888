<template>
  <div>
    <Header />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/error/HeaderError.vue'
import Main from '../components/error/MainError.vue'
import Footer from '../layout/Footer.vue'

export default {
  name: 'Error404',

  components: {
    Header,
    Main,
    Footer
  },
  
  data () {
    return {
      leftDrawerOpen: false
    }
  },
}
</script>

<style lang="stylus" scoped>
 